/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import styles from "./App.module.scss";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import WorkListPage from "./pages/workList/index.js";
import NewWorkPage from "./pages/newWork/index.js";
import DocList from "./pages/docList/index.js";
import Documents from "./pages/documents/index.js";
import { Auth } from "./config/auth";
import { api } from "./config/api";
import React, { useContext, useEffect, useState } from "react";
import LoginPage from "./pages/login/index";
import SignUpPage from "./pages/signUp/SignUpPage";
import CostProjectsPage from "./pages/costProjects/CostProjectsPage";
import MyPage from "./pages/myPage/MyPage";
import NoticeList from "./pages/noticeList/noticeList";
import NoticeDetail from "./pages/noticeList/noticeDetail/noticeDetail";
// import { Menu, Dropdown, Badge } from "antd";
import Notifications from "@material-ui/icons/Notifications";
import PersonIcon from "@material-ui/icons/Person";
import { pages } from "./constants/PagePaths";
import { useHistory, Redirect } from "react-router-dom";
import AlertContext from "./contexts/AlertContext";
import LectureRoomContainer from "./pages/lectureRoom/LectureRoomContainer";
import LectureRoomStudyContainer from "./pages/lectureRoom/LectureRoomStudyContainer";
import SampleStatementListContainer from "./pages/sampleStatementList/SampleStatementListContainer";
import InquiryContainer from "./pages/inquiry/InquiryContainer";

import _ from "lodash";
import FindAccountContainer from "./pages/findAccount/FindAccountContainer";
import MainContainer from "./pages/main/MainContainer";
import { privacyPolicy, termsOfService } from "./constants/Terms";
import PaymentContainer from "./pages/payment/PaymentContainer";
import { util } from "./config/util";
import { Cookies } from "./constants/Cookies";
import WithdrawalContainer from "./pages/withdrawal/WithdrawalContainer";
import ChangePasswordContainer from "./pages/changePassword/ChangePasswordContainer";
import GA4React from "ga-4-react";
import DupLoginContext from "./contexts/DupLoginContext";
import SampleStatementDetailContainer from "./pages/sampleStatementDetail/SampleStatementDetailContainer";
import Contact from "./pages/contact/Contact";
import InquiryBoard from "./pages/contact/InquiryBoard";
// import RequestApplication from "./pages/contact/RequestApplication";
import sns2 from "./assets/img/icon/youtube.png";
import UserBoardListContainer from "./pages/userBoardList/UserBoardListContainer";
import UserBoardDetailContainer from "./pages/userBoardDetail/UserBoardDetailContainer";
import UserBoardFormContainer from "./pages/userBoardForm/UserBoardFormContainer";
import ServiceRequestDocContainer from "./pages/contact/serviceRequestDoc/ServiceRequestDocContainer";
import VideoMain from "./pages/video/components/main/VideoMain";
import ScrollToTop from "./modules/ScrollToTop";
import DataListContainer from "./pages/constructionData/DataListContainer";
import ExcelContainer from "./pages/excel/ExcelContainer";
import ExcelContractContainer from "./pages/excelContract/ExcelContractContainer";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AlarmModal from "./components/AlarmModal";
import MyPageLayout from "./components/MyPageLayout";
import CouponContainer from "./pages/myPage/components/CouponContainer";
import LectureContainer from "./pages/lectureRoom/LectureContainer";
import LevelTest from "./pages/levelTest/LevelTest";
import LevelTestContainer from "./pages/levelTest/LevelTestContainer";
import LevelTestComplete from "./pages/levelTest/LevelTestComplete";
import Video from "./pages/video/components/video/Video";
import RequestApplicationContainer from "./pages/requestApplication/RequestApplicationContainer";
import MyPageVideo from "./pages/myPage/MyPageVideo";
import MyPagePayment from "./pages/myPage/MyPagePayment";
import CostProjectsDetailPage from "./pages/costProjects/CostProjectsDetailPage";
import RequestApplication from "./pages/contact/RequestApplication";
import DataDetailContainer from "./pages/constructionData/DataDetailContainer";
import PaymentComplete from "./pages/payment/PaymentComplete";
import MyPageQna from "./pages/myPage/MyPageQna";
import MyPageQnaDetail from "./pages/myPage/MyPageQnaDetail";
import Callback from "./pages/oauth/Callback";
import MyPageContact from "./pages/myPage/MyPageContact";
import MyPageContactDetail from "./pages/myPage/MyPageContactDetail";
import SocialSignup from "./pages/oauth/SocialSignUp";
import MyPageQnaReplyDetail from "./pages/myPage/MyPageQnaReplyDetail";

declare global {
  interface Window {
    AOS: any;
  }
}

function App() {
  //IE일 경우 안내페이지로 리다이렉트
  const isIE = /*@cc_on!@*/ false || !!(document as any).documentNode;
  if (isIE) {
    window.location.href = "https://ie.ddusul.com";
  }
  const location = useLocation();

  useEffect(() => {
    const { AOS } = window;
    AOS.init();
  }, [location]);

  const auth = Auth.getInstance();

  const [currentEmail, setCurrentEmail] = useState();
  const [alarms, setAlarms] = useState<any>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const history = useHistory();
  const alert = useContext(AlertContext);
  const dlContext = useContext(DupLoginContext);

  const clearUserInfo = () => {
    try {
      api.logout();
      history.push(pages.index);
    } catch (err) {}
    setCurrentEmail(undefined);
    util.deleteCookie(Cookies.authKey);
    window.location.reload();
  };

  const checkUser = () => {
    const buildGoogleAanlyticsAsync = async () => {
      try {
        const gTagId = process.env.REACT_APP_GTAG_ID;
        const ga4react = new GA4React(gTagId!);
        await ga4react.initialize();
        if (util.getCookie(Cookies.authKey)) {
          ga4react.gtag("config", process.env.REACT_API_GTAG_ID, {
            user_id: util.getCookie(Cookies.authKey),
          });
        }
      } catch (error) {}
    };

    buildGoogleAanlyticsAsync();
    if (util.getCookie(Cookies.authKey)) {
      api
        .getMyInfo()
        .then((result: any) => {
          //로그인 되어있음
          auth.email = result.data.email;
          auth.lastName = result.data.lastName;
          setCurrentEmail(auth.email);
          api.getAlarmsAsync(0, 10, false).then((result: any) => {
            const data = result?.data?.alarms?.map((alarm: any) => {
              if (alarm.link?.[0] !== "/") {
                return { ...alarm, link: "/" + alarm.link };
              } else return alarm;
            });
            setAlarms(data);
            setUnreadCount(result.data.unread_count);
          });
        })
        .catch((error) => {
          //로그인 되어있지 않음
          if (error?.response?.status === 401) {
            alert.showAlert(
              "다른 브라우저에서 로그인 혹은 로그아웃하여\n현재 브라우저에서 로그아웃 합니다.",
              () => {
                history.push(pages.index);
                clearUserInfo();
              }
            );
          }
        });
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  const viewAllAlarm = () => {
    try {
      api.viewAllAlarms().then((result) => checkUser());
    } catch (error) {
      console.error(error);
    }
  };

  const viewAlarm = async (index: number) => {
    try {
      await dlContext.checkDupLogin(() =>
        api.viewAlarm({
          id: alarms[index].id,
        })
      );
      await dlContext.checkDupLogin(() =>
        api.getAlarmsAsync(0, 10, false).then((result: any) => {
          const data = result?.data?.alarms?.map((alarm: any) => {
            if (alarm.link?.[0] !== "/") {
              return { ...alarm, link: "/" + alarm.link };
            } else return alarm;
          });
          setAlarms(data);
          setUnreadCount(result.data.unread_count);
        })
      );
      history.push(alarms[index].link);
    } catch (error) {
      console.error(error);
    }
  };

  // //알람 버튼 누를 시에 뜨는 menu 목록
  // const alertMenu = (
  //   <Menu className={styles["alarm-wrapper"]}>
  //     {alarms.map((alarm: any, index: number) => (
  //       <div
  //         onClick={() => viewAlarm(index)}
  //         className={`${styles["alarm-container"]} ${
  //           alarm.view ? styles.read : ""
  //         }`}
  //         key={index}
  //       >
  //         <div className={styles.ellipsis}>{alarm.text}</div>
  //       </div>
  //     ))}
  //     {alarms.length === 0 && (
  //       <div className={`${styles["alarm-container"]} ${styles.read}`}>
  //         <div className={styles.ellipsis}>읽지 않은 알람이 없습니다.</div>
  //       </div>
  //     )}
  //     {alarms.length > 0 && (
  //       <div className={styles["alarm-etc"]}>
  //         <span onClick={viewAllAlarm}>모두 읽음 처리</span>
  //       </div>
  //     )}
  //   </Menu>
  // );

  const logout = () => {
    api
      .logout()
      .then((result) => {
        auth.email = undefined;
        auth.lastName = undefined;
        setCurrentEmail(undefined);
        history.push("/");
      })
      .catch((error) => {
        window.location.reload();
        console.error(error);
      });
  };
  return (
    <React.Fragment>
      <Switch>
        <Route path="*" exact>
          <div className={styles["app-wrap"]}>
            {/* 상단 메뉴 */}
            <Header currentEmail={currentEmail} unreadCount={unreadCount} />
            <Switch>
              <ScrollToTop>
                <Route
                  path={`${pages.video}/(.*)?`}
                  exact
                  component={VideoMain}
                />
                <Route exact={true} path="/">
                  <MainContainer clearUserInfo={clearUserInfo} />
                </Route>

                <div className={styles["inner-body"]}>
                  <Route exact={true} path={pages.login}>
                    <LoginPage checkUser={checkUser} />
                  </Route>
                  <Route exact={true} path={`${pages.oauth2}/:provider`}>
                    <Callback checkUser={checkUser} />
                  </Route>
                  <Route exact={true} path={`${pages.cert}`}>
                    <SocialSignup checkUser={checkUser} />
                  </Route>
                  <Route exact={true} path={pages.worklists}>
                    <WorkListPage />
                  </Route>
                  <Route exact={true} path={pages.newwork}>
                    <NewWorkPage />
                  </Route>
                  <Route exact={true} path={pages.doclist}>
                    <DocList />
                  </Route>
                  <Route exact={true} path={`${pages.worklists}/:id/documents`}>
                    <Documents />
                  </Route>
                  <Route
                    exact={true}
                    path={`${pages.worklists}/:id/documents/:chapter`}
                  >
                    <Documents />
                  </Route>
                  <Route
                    exact={true}
                    path={`${pages.worklists}/:id/documents/PhotoList/:key`}
                  >
                    <Documents />
                  </Route>
                  <Route exact={true} path={pages.noticelist}>
                    <NoticeList />
                  </Route>
                  <Route exact={true} path={`${pages.notice}/:id`}>
                    <NoticeDetail />
                  </Route>

                  <Route exact={true} path={pages.signup}>
                    <SignUpPage />
                  </Route>
                  <Route exact={true} path={pages.costprojects}>
                    <Redirect to={`${pages.costprojects}/normal`} />
                  </Route>
                  <Route exact={true} path={`${pages.costprojects}/:type`}>
                    <CostProjectsPage />
                  </Route>
                  <Route exact={true} path={`${pages.costprojects}/detail/:id`}>
                    <DupLoginContext.Consumer>
                      {(dupLogin) => (
                        <CostProjectsDetailPage dupLogin={dupLogin} />
                      )}
                    </DupLoginContext.Consumer>
                  </Route>
                  <Route exact={true} path={pages.mypage}>
                    <MyPageLayout child={<MyPage />} />
                  </Route>
                  <Route exact={true} path={pages.mypage + "/video"}>
                    <MyPageLayout child={<MyPageVideo />} />
                  </Route>
                  <Route exact={true} path={pages.mypage + "/payment"}>
                    <MyPageLayout child={<MyPagePayment />} />
                  </Route>
                  <Route exact={true} path={pages.mypage + "/qna"}>
                    <MyPageLayout child={<MyPageQna />} />
                  </Route>
                  <Route exact={true} path={pages.mypage + "/qna/:id"}>
                    <MyPageLayout child={<MyPageQnaDetail />} />
                  </Route>
                  <Route exact={true} path={pages.mypage + "/qna/reply/:id"}>
                    <MyPageLayout child={<MyPageQnaReplyDetail />} />
                  </Route>
                  <Route exact={true} path={pages.mypage + "/contact"}>
                    <MyPageLayout child={<MyPageContact />} />
                  </Route>
                  <Route exact={true} path={pages.mypage + "/contact/:id"}>
                    <MyPageLayout child={<MyPageContactDetail />} />
                  </Route>
                  <Route exact={true} path={`${pages.lectureroom}`}>
                    <LectureContainer />
                  </Route>
                  <Route exact={true} path={`${pages.lectureroom}/:category`}>
                    <LectureRoomContainer />
                  </Route>
                  <Route
                    exact={true}
                    path={`${pages.lectureroom}/:category/:id`}
                  >
                    <LectureRoomStudyContainer />
                  </Route>
                  <Route exact={true} path={`${pages.inquiry}`}>
                    <InquiryContainer />
                  </Route>
                  {/* <Route exact={true} path={`${pages.faq}`}>
                      <FaqContainer />
                    </Route> */}
                  <Route exact={true} path={`${pages.findaccount}`}>
                    <FindAccountContainer />
                  </Route>
                  <Route exact={true} path={`${pages.payment}`}>
                    <PaymentContainer />
                  </Route>
                  <Route exact={true} path={`${pages.paymentComplete}`}>
                    <PaymentComplete />
                  </Route>
                  <Route exact={true} path={`${pages.withdrawal}`}>
                    <MyPageLayout child={<WithdrawalContainer />} />
                  </Route>
                  <Route exact={true} path={`${pages.changepassword}`}>
                    <MyPageLayout child={<ChangePasswordContainer />} />
                  </Route>
                  <Route exact={true} path={`${pages.coupon}`}>
                    <MyPageLayout child={<CouponContainer />} />
                  </Route>
                  <Route exact={true} path={`${pages.statementlist}`}>
                    <SampleStatementListContainer />
                  </Route>
                  <Route exact={true} path={`${pages.contact}`}>
                    <Contact />
                  </Route>
                  <Route exact={true} path={`${pages.statementdetail}/:id`}>
                    <SampleStatementDetailContainer />
                  </Route>
                  <Route exact={true} path={`${pages.requestapplication}`}>
                    {/* <RequestApplication /> */}
                    <ServiceRequestDocContainer />
                  </Route>
                  <Route
                    exact={true}
                    path={`${pages.requestapplication}/:type`}
                  >
                    {/* <RequestApplication /> */}
                    <RequestApplicationContainer />
                  </Route>
                  <Route exact={true} path={`${pages.inquiryboard}`}>
                    <InquiryBoard />
                  </Route>
                  <Route exact={true} path={`${pages.userboardlist}`}>
                    <UserBoardListContainer />
                  </Route>
                  <Route exact={true} path={`${pages.userboarddetail}/:id`}>
                    <UserBoardDetailContainer />
                  </Route>
                  <Route exact={true} path={`${pages.userboardform}`}>
                    <UserBoardFormContainer />
                  </Route>
                  <Route exact={true} path={`${pages.data}`}>
                    <DataListContainer />
                  </Route>
                  <Route exact={true} path={`${pages.data}/:id`}>
                    <DataDetailContainer />
                  </Route>
                  <Route exact={true} path={`${pages.excel}`}>
                    <ExcelContainer />
                  </Route>
                  <Route exact={true} path={`${pages.excelContract}`}>
                    <ExcelContractContainer />
                  </Route>
                  <Route exact={true} path={`${pages.leveltest}/:id`}>
                    <LevelTestContainer />
                  </Route>
                </div>
              </ScrollToTop>
            </Switch>
            <Footer />
          </div>
          <AlarmModal alarms={alarms} />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default App;
