import { Router } from "@material-ui/icons";
import { useContext, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { api } from "../../config/api";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import { Imp } from "../../models/payment/iamport/IMP";
import {
  ImpCertificationRequestModel,
  ImpCertificationResponseModel,
} from "../../models/payment/iamport/ImpModels";
import GlobalEnvironments from "../../modules/globalEnvironment/GlobalEnvironment";

interface Props {
  provider: string;
  providerId: string;
  email?: string;
}

export default (props: any) => {
  const location = useLocation<Props>();
  const { showAlert } = useContext(AlertContext);
  const history = useHistory();
  const state = location.state;
  useEffect(() => {
    if (!state.providerId) {
      showAlert("잘못된 접근입니다.", () => {
        history.push(pages.login);
      });
    }
  }, []);

  const certify = () => {
    const data: ImpCertificationRequestModel = {
      merchant_uid: `mid_${new Date().getTime()}`,
      company: `https://${process.env.REACT_APP_DOMAIN}.com/`,
    };
    Imp.certification(data, async (res: ImpCertificationResponseModel) => {
      if (!res.success && res.error_code === "F0000") {
        return;
      } else {
        const result = await api.socialSignUp(
          state.provider,
          state.providerId,
          res.imp_uid
        );

        if (result.data.key) {
          const key = result.data.key;
          const now = new Date();
          util.setCookie({
            cookieName: Cookies.authKey,
            cookieValue: key,
            cookieExpire: new Date(now.setFullYear(now.getFullYear() + 1)),
            cookieDomain: GlobalEnvironments.getDomainUrl(),
            cookiePath: "/",
          });
          props.checkUser();
          showAlert(result.data.message, () => {
            history.replace(pages.index);
            history.push(pages.index);
          });
        } else {
          showAlert(result.data.errorMsg);
        }
      }
    });
  };

  return (
    <main id="wrap" className="bg-light-md">
      <article>
        <section>
          <div className="container page">
            <div className="section-header">가입인증</div>
            <div
              className="col-md-4 mt-3 mx-auto form-wrap"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="text-dark mb-6">
                뚜껑설계 로그인 후 더욱 편리한 서비스를 이용해 보세요.
              </h4>
              <div className="col-12">
                <button
                  className="btn btn-xlg btn-primary w-100"
                  type="button"
                  onClick={() => {
                    // console.log(1);
                    certify();
                  }}
                >
                  인증하기
                </button>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};
