import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { pages } from "../../../constants/PagePaths";

export default () => {
  const banners = [
    {
      title: (
        <>
          엑셀로 하는 번거로운 작업 그만!
          <br />
          업무 자동화로 편하게 업무를 처리하세요
        </>
      ),
      subTitle: "엑셀 자동화 서비스 출시!",
      imageUrl: "/images/ddusul/main-1.png",
      link: pages.excel,
    },
    {
      title: (
        <>
          새로워진 뚜껑설계가 여러분과 함께합니다
          <br />
          더욱 편리해진 서비스를 이용해보세요
        </>
      ),
      subTitle: "뚜껑설계 홈페이지 리뉴얼 출시!",
      imageUrl: "/images/ddusul/main-2.png",
      link: "",
    },
    {
      title: (
        <>
          아직도 건설서류가 어려우세요?
          <br />
          뚜껑설계 용역서비스를 이용해보세요
        </>
      ),
      subTitle: "빠르고 정확한 설계용역!",
      imageUrl: "/images/ddusul/main-3.png",
      link: "/contact",
    },
  ];
  const history = useHistory();
  useEffect(() => {
    ///***** START SLICK WITH PROGRESSBAR****///
    // Slick Slide Counters
    var $mainslider = $(".heroslider");
    $mainslider.on("init", function (event, slick) {
      $(this).append(
        '<div class="slick-bottom-bar"><div class="slick-bottom-wrap"><div class="slick-counter"><span class="current"></span><span>/</span><span class="total"></span></div><div class="slider-progress"><div class="progress"></div></div></div></div>'
      );
      $(".current").text(slick.currentSlide + 1);
      $(".total").text(slick.slideCount);
    });
    $mainslider.on(
      "beforeChange",
      function (event, slick, currentSlide, nextSlide) {
        $(".current").text(nextSlide + 1);
      }
    );

    // Slick Slider Options
    $mainslider.slick({
      draggable: true,
      arrows: true,
      dots: false,
      //fade:true,
      infinite: true,
      prevArrow: $(".prevArrow"),
      nextArrow: $(".nextArrow"),
      speed: 1000,
      autoplaySpeed: 8000,
      mobileFirst: true,
      pauseOnHover: true,
    });

    // Slider Progressbar Start Here
    var time = 3;
    var $bar, $mainslider, isPause, tick, percentTime;

    $bar = $(".slider-progress .progress");

    function startProgressbar() {
      resetProgressbar();
      percentTime = 0;
      isPause = false;
      tick = setInterval(interval, 15);
    }

    function interval() {
      if (isPause === false) {
        percentTime += 1 / (time + 0.1);
        $bar.css({
          width: percentTime + "%",
        });
        if (percentTime >= 100) {
          $mainslider.slick("slickNext");
        }
      }
    }
    function resetProgressbar() {
      $bar.css({
        width: 0 + "%",
      });
      clearTimeout(tick);
    }
    // Reset Progressbar When Slide Change
    $mainslider.on("afterChange", function (event, slick, currentSlide) {
      startProgressbar();
    });

    $(".sliderwrap").on({
      mouseenter: function () {
        isPause = true;
      },
      mouseleave: function () {
        isPause = false;
      },
    });
    startProgressbar();
  }, []);

  return (
    <section>
      <div className="mainbannerwrap">
        <div className="sliderwrapper">
          <div className="sliderwrap">
            <div className="heroslider">
              {banners.map((banner) => {
                return (
                  <div
                    className="item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (banner.link != "") history.push(banner.link);
                    }}
                  >
                    <div className="item-box-wrap">
                      <div className="item-box">
                        <p className="item-txt">{banner.subTitle}</p>
                        <h2 className="item-title">{banner.title}</h2>
                      </div>
                    </div>
                    <div className="overlay"></div>
                    <img src={banner.imageUrl} alt="슬라이드이미지" />
                  </div>
                );
              })}
            </div>
            <div className="indicator">
              <div className="indicator-wrap">
                <button className="btn prevArrow">
                  <span className="material-symbols-outlined">
                    chevron_left
                  </span>
                </button>
                <button className="btn nextArrow">
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
