/* eslint-disable eqeqeq */
/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../config/api";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import { pages } from "../../constants/PagePaths";
import { passwordRegex } from "../../constants/RegexData";
import AlertContext from "../../contexts/AlertContext";
import { Imp } from "../../models/payment/iamport/IMP";
import FindAccountPresenter from "./FindAccountPresenter";

interface Props {}

export default (props: Props) => {
  const history = useHistory();
  const alert = useContext(AlertContext);
  const [pwToken, setPwToken] = useState("");
  const [isSuccessFindPw, setIsSuccessFindPw] = useState(false);
  const [foundedIdInfo, setFoundedIdInfo] = useState("");
  const [selectedFindOption, setSelectedFindOption] = useState<number>(0);
  const [isCertificationInProgress, setIsCertificationInProgress] =
    useState(false);

  useEffect(() => {
    const key = util.getCookie(Cookies.authKey);
    if (key) {
      history.push(pages.index);
    }
    setIsSuccessFindPw(true);
  });

  const handleClickFindOption = (index: number) => {
    setIsSuccessFindPw(false);
    setFoundedIdInfo("");
    setIsCertificationInProgress(false);
    setSelectedFindOption(index);
    setPwToken("");
  };

  const handleClickIdCertification = (name: string, phoneNum: string) => {
    if (!name || !phoneNum) {
      alert.showAlert("이름과 휴대폰 번호를 입력해주세요 !");
      return;
    }
    Imp.certification(
      {
        merchant_uid: `mid_${new Date().getTime()}`,
        name,
        phone: phoneNum,
      },
      async (res) => {
        if (!res.success && res.error_code === "F0000") {
          return;
        }
        const result: any = await api.postFindIdAsync({
          imp_uid: res.imp_uid,
        });
        if (result.status === 200) {
          setFoundedIdInfo(result.data.id);
        } else {
          alert.showAlert(`일치하는 정보가 없습니다.`);
        }
      }
    );
  };

  const handleClickPwCertification = (id: string, phoneNum: string) => {
    if (!id || !phoneNum) {
      alert.showAlert("아이디와 휴대폰 번호를 입력해주세요 !");
      return;
    }

    Imp.certification(
      {
        merchant_uid: `mid_${new Date().getTime()}`,
      },
      async (res) => {
        if (!res.success && res.error_code === "F0000") {
          return;
        }
        const result = await api.postFindPwAsync({
          imp_uid: res.imp_uid,
          email: id,
        });
        if (result.status === 200) {
          setPwToken(result.data.token);
          setIsSuccessFindPw(true);
        } else {
          alert.showAlert(`일치하는 정보가 없습니다.`);
        }
      }
    );
  };

  const handleChangePhoneNumber = () => {
    setIsCertificationInProgress(false);
  };

  const handleClickBackToLogIn = () => {
    history.push(pages.login);
  };

  const handleClickChangePw = async (pw: string, pwConfirm: string) => {
    if (!passwordRegex.test(pw)) {
      alert.showAlert("비밀번호 양식을 맞춰주세요.");
      return;
    }

    if (pw != "" && pw === pwConfirm) {
      const res = await api.postChangePwInFindAccountAsync(pw, pwToken);
      if (res.status === 200) {
        alert.showAlert("변경이 완료되었습니다.", () => {
          history.push(pages.login);
        });
      }
    } else {
      alert.showAlert("변경할 비밀번호가 일치하지 않습니다.");
    }
  };

  return (
    <FindAccountPresenter
      selectedFindOption={selectedFindOption}
      foundedIdInfo={foundedIdInfo}
      isSuccessFindPw={isSuccessFindPw}
      isCertificationInProgress={isCertificationInProgress}
      onClickBackToLogIn={handleClickBackToLogIn}
      onClickFindOption={handleClickFindOption}
      onClickChangePw={handleClickChangePw}
      onChangePhoneNumber={handleChangePhoneNumber}
      onClickIdCertification={handleClickIdCertification}
      onClickPwCertification={handleClickPwCertification}
    />
  );
};
